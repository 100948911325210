import React from 'react';
import tiendas from '../../assets/images/tiendas.png';
import openPayBlanco from '../../assets/images/kit_openpay/openpay_contraste.png';
import API from '../../utils/api';
import AbonarFormsy from '../AbonarFormsy';
import Formsy from 'formsy-react';
import utils from '../../utils/utils';
import {
  Modal,
} from 'react-bootstrap/lib';

import { publish, subscribe, unsubscribe } from '../../utils/events'

import Button from '../Form/Button'
import Text from '../Text/Text';


const StoresAvailable = () => {
  return (
    <a
      href="https://www.paynet.com.mx/mapa-tiendas/index.html"
      target="_blank"
      className="flex-row row-no-padding row-center "
      style={{ marginBottom: '15px' }}
      rel="noopener noreferrer"
    >
      <div className="flex-col col-center">
        <div className="flex-row row-center ">
          <div className="flex-col col-center text-center">
            <i className="ion-ios-location" style={{ marginRight: '3px' }} />
            Consulta aquí tu tienda más cercana
          </div>
        </div>

        <div className="flex-row row-center row-no-padding">
          <div className="flex-col col-center text-center col-no-padding">
            <img src={tiendas} style={{ maxHeight: '50px', filter: 'dropShadow(0 0 4px)' }}></img>
          </div>
          <div className="flex-col col-center text-center col-no-padding">
            <img
              className="img-responsive openPayLogo"
              src={openPayBlanco}
              style={{ maxHeight: '35px' }}
            />
          </div>
        </div>
      </div>
    </a>
  );
};


const DepositResponse = ({
  showDepositResponse,
  closeDepositResponse,
  imageUrl,
  pdfUrl,
  error,
  nick,
  other_amount,
  convertToPesos,
  amount,
}) => {
  //let montoDeposito = 0;

  return (
    <Modal
      show={showDepositResponse}
      className="responseDepositModal absoluteModal"
      dialogClassName="responseDepositDialog"
      backdropClassName="responseDepositBackdrop"
    >
      <div className="transactionPanel">
        <div id="panelTitle" className={`panel-abonar-titulo ${error ? 'failed' : ''}`}>
          {error ? (
            <span>Tenemos un problema.</span>
          ) : (
              <span>!Tú referencia se creó con exito!</span>
            )}
          <button className="cerrar-panel" onClick={closeDepositResponse}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty"></i>
            </div>
          </button>
        </div>
        <div className="toditoPanelResponse">
          {!error ? (
            <span className="icon-success flex-container  justify column align">
              <i className="ion-checkmark-circled"></i>
              <strong> ¡Tu referencia se creo con éxito!</strong>
            </span>
          ) : (
              <span className="icon-failed flex-container  justify column align">
                <i className="ion-close-circled"></i>
                <span>{nick} tenemos un problema </span>
              </span>
            )}
          <div className="flex-col col-80 col-offset-10 col-no-padding text-center transaction-success">
            {!error ? (
              <React.Fragment>
                <div className="flex-row row-center row-no-padding">
                  <div className="flex-col col-no-padding">
                    <div className="flex-row row-center row-no-padding">
                      <span className="flex-col col-center text-center">
                        Acude a tu tienda más cercana y presenta este código de barras:
                      </span>
                    </div>
                    <div className="flex-row ">
                      <strong className="flex-col col-center text-center">
                        {' '}
                        Código para abonar {convertToPesos(amount, other_amount)} <small>MXN</small>
                      </strong>
                    </div>
                    <div className="flex-row ">
                      <img
                        className="flex-col col-center img-responsive code-deposit"
                        src={imageUrl}
                      ></img>
                    </div>
                  </div>
                </div>
                <div className="flex-container justify align ">
                  <small> Ó si prefieres... </small>
                </div>
                <StoresAvailable />
                <a href={pdfUrl} target="_blank" className="flex-row row-center" rel="noopener noreferrer">
                  <Button
                    type="submit"
                    bgColor='#88ba44'
                    style={{
                      maxWidth: 300,
                      margin: '1em auto'
                    }}
                  >
                    <span className="">
                      <div>
                        <i className="ion-android-download" />
                        {` Descarga tú código `}
                      </div>
                    </span>
                  </Button>
                </a>
              </React.Fragment>
            ) : (
                <div className="flex-container column text-center align justify">
                  <strong>Error: {error.code} </strong>
                  <span style={{ marginBottom: '15px' }}>
                    {error.message ? error.message : error.length > 1 ? error : 'Algo no anda bien'}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

class StorePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfUrl: null,
      imageUrl: null,
      paymentDataModel: {
        name: null,
        last_name: null,
        amount: 4,
        other_amount: null,
        gateway: 'openpay',
        method: 'convenience',
      },
      canSubmit: false,
      submitting: false,
      requestStatus: null,
      fieldsWithErrors: null,
    };

    this.convertToPesos = (cant, otraCantidad) => {
      // console.log(cant, otraCantidad);
      switch (cant) {
        case 2:
          return '$50';
          break;
        case 4:
          return '$200';
          break;
        case 5:
          return '$100';
          break;
        case 7:
          return '$500';
          break;
        case 'other':
          return `$${otraCantidad}`;
          break;
      }
    };

    this.handleScrollModal = () => {
      console.log('Handle Scroll');
      setTimeout(() => {
        window.scrollTo(0, 0);
        // $('window').animate({ scrollTop: 0 }, 'slow');
      }, 250);
    };

    this.handleSubmit = (status) => {
      this.setState({ canSubmit: status });
    };

    this.handleCardData = (e, category) => {
      // console.log( e, category);
      let temporalPaymentData = this.state.paymentDataModel;
      temporalPaymentData[category] = e.target.value;

      console.log('Payment Model');
      console.log(temporalPaymentData);

      this.setState({
        paymentDataModel: temporalPaymentData,
      });
    };

    this.closeResponse = () => {
      this.setState({ showResponse: false, error: null });
    };

    this.enableButton = () => {
      this.setState({ canSubmit: true });
    };

    this.disableButton = () => {
      let fieldsWithErrors = [];
      // console.log('Invalid Form');
      // console.log(this.refs.form);
      this.refs.form.inputs.forEach((input) => {
        let fieldObject = { name: null, errorMessage: null };
        // console.log(input.props.name);
        // console.log('pristine:',input.isPristine());
        // console.log('valid:',input.isValid());
        if (
          !input.isPristine() &&
          !input.isValid() &&
          (input.props.name === 'expiration_month' ||
            input.props.name === 'exp_year' ||
            input.props.name === 'cvv2')
        ) {
          fieldObject.name = input.props.name;
          fieldObject.errorMessage = input.props.validationError;
          fieldsWithErrors = [...fieldsWithErrors, fieldObject];
        }
      });
      //  console.log(  'fieldsWithErrors', fieldsWithErrors );
      this.setState({ canSubmit: false, fieldsWithErrors: fieldsWithErrors });
    };

    this.showPaymentResponse = (data) => {
      console.log(data);
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      this.setState({ submitting: false, showResponse: true, newBalance: data.balance }, () => {
        const transaction = { amount: data.amount };
        console.log('send this transaction', transaction);
        // API.trackPurchaseSuccessFB( transaction );
        // const balanceObj = data.balance;
        // // if( this.props.updateUserBalance ){
        //   this.props.updateUserBalance( balanceObj );
        //   if( this.props.dispatch  ){
        //     this.props.updateUserBalance( this.props.dispatch, balanceObj  );
        //   }
        //   else{
        //     this.props.updateUserBalance(  balanceObj  );
        //   }
        // }
      });
    };

    this.showPaymentError = (error, code) => {
      console.log(error);
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      this.setState(
        { submitting: false, showResponse: true, error: { message: error, code: code } },
        this.handleScrollModal()
      );
    };

    this.fakeSubmitAPI = (data) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ success: true, newBalance: 999999 });
          // reject( { success: false, error: { status: 500, message: 'Algo no anda bien' } } );
          // resolve( { success: false, error: { status: 500, message: 'Algo no anda bien' } } );
        }, 800);
      });
    };

    this.callbackPayment = (data) => {
      this.handleScrollModal();
      console.log(data);
      if (data.success) {
        let reference = data.response.payment_method.reference;
        let url =
          process.env.NODE_ENV === 'production'
            ? `https://dashboard.openpay.mx/paynet-pdf/myabfqccohuj4kszwr7y/${reference}`
            : `https://sandbox-dashboard.openpay.mx/paynet-pdf/mqfki2pbqpbve54kabor/${reference}`;

        this.props.updateUserInfo();

        this.setState({
          pdfUrl: url,
          imageUrl: `https://api.openpay.mx/barcode/${reference}?width=2&height=80&text=true`,
        });
        this.showPaymentResponse(data);
      } else {
        this.showPaymentError(data.error, data.code);
      }
    };

    this.handleDepositoCantidad = (eventKey) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = eventKey === 'other' ? '300' : null;
      temporalPaymentDataModel.amount = eventKey;
      console.log(temporalPaymentDataModel);

      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.handleOtraCantidad = (e) => {
      let temporalPaymentDataModel = this.state.paymentDataModel;

      temporalPaymentDataModel.other_amount = e.target.value.replace(/[e,E]/, '');
      console.log(temporalPaymentDataModel);
      this.setState({ paymentDataModel: temporalPaymentDataModel });
    };

    this.openPdf = () => {
      window.location = this.state.pdfUrl;
    };

    this.openStoreListings = () => {
      window.open('https://www.paynet.com.mx/mapa-tiendas/index.html', '_blank');
    };

    this.submitPaymentAwaitSMS = () => {
      this.submitPayment()
    }

    subscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)

    this.submitPayment = () => {
      const userData = utils.getUserInfo()

      if (!userData.identity_verified) {
        return publish('SMS:SHOW_MODAL')
      }

      console.log('submitPayment', this.state.paymentDataModel);
      this.setState({ submitting: true });
      if (this.props.blockUi) {
        this.props.blockUi();
      }
      API.postDepositConvenience(this.state.paymentDataModel)
        .then((response) => {
          console.log(response);
          this.callbackPayment(response.data);
        })
        .catch((err) => {
          console.error(err);
          this.showPaymentError(err);
        });
    };
    this.resetData = () => {
      this.setState({
        requestStatus: null,
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.amount !== this.props.amount ||
      prevProps.other_amount !== this.props.other_amount
    ) {
      let newPaymentData = this.state.paymentDataModel;

      newPaymentData.amount = this.props.amount;
      newPaymentData.other_amount = this.props.other_amount;
      this.setState({ paymentDataModel: newPaymentData });
    }
  }
  componentDidMount() {
    let newPaymentData = this.state.paymentDataModel;

    newPaymentData.amount = this.props.amount ? this.props.amount : null;
    newPaymentData.other_amount = this.props.other_amount ? this.props.other_amount : null;

    this.setState({
      paymentDataModel: newPaymentData,
      mounted: true,
    });
  }

  componentWillUnmount () {
    unsubscribe('SMS:ACOUNT_VERIFIED', this.submitPaymentAwaitSMS)
  }

  render() {
    let userData = utils.getUserInfo();

    if (!this.props.show) {
      return null;
    }
    // console.log( this.state.fieldsWithErrors )
    return (
      <React.Fragment>
        <DepositResponse
          amount={this.props.amount}
          other_amount={this.props.other_amount}
          dispatch={this.props.dispatch}
          showDepositResponse={this.state.showResponse}
          closeDepositResponse={this.closeResponse}
          nick={utils.getUserInfo().nick}
          imageUrl={this.state.imageUrl}
          pdfUrl={this.state.pdfUrl}
          openPdf={this.openPdf}
          convertToPesos={this.convertToPesos}
          newUserBalance={this.state.newBalance}
          error={this.state.error}
          updateUserInfo={this.props.updateUserInfo}
        />
        <div className="flex-row row-center row-no-padding mt-1" style={{ alignItems: 'flex-start' }}>
          <div className="flex-col col-no-padding" style={{marginLeft: '3rem'}}>
            <div className="flex-row row-center">
              <h4 style={{ color: '#e5e6e8' }} className="flex-col col-center text-center">
                ¿CÓMO ABONAR?
              </h4>
            </div>
            <div className="flex-row row-center">
              <ol className=" flex-col col-90 col-offset-5 " style={{fontSize: '12px'}}>
                {!userData.name ? <li> Dínos tu nombre</li> : null}
                <li> Obten tu Referencia</li>
                <li>
                  {' '}
                  Imprime tu referencia o llévala en tu celular a cualquiera de las tiendas
                  participantes
                </li>
                <li> Entrega tu referencia al cajero, realiza tu pago y guarda tu comprobante</li>
                <li> En cuestión de horas verás tu saldo abonado </li>
              </ol>
            </div>
          </div>
          <div className='flex-col'>
            {this.props.amountComponent}
          </div>
        </div>
        {!userData.name ? (
          <Formsy
            ref="form"
            onValid={() => {
              this.handleSubmit(true);
            }}
            onInvalid={() => {
              this.handleSubmit(false);
            }}
          >
            <div className="flex-row row-center row-no-padding mt-1">
              <AbonarFormsy
                col="col-45"
                type={'TEXT'}
                required
                resetForm={null}
                defaultValue={''}
                name={'name'}
                placeholder='NOMBRE'
                validations={{ minLength: 2 }}
                validationError={'Utiliza tu nombre.'}
                inputHandler={this.handleCardData}
              />
              <AbonarFormsy
                col="col-50 col-offset-5"
                type={'TEXT'}
                required
                resetForm={null}
                defaultValue={''}
                name={'last_name'}
                placeholder='APELLIDOS'
                validations={{ minLength: 2 }}
                validationError={'Utiliza tu apellido.'}
                inputHandler={this.handleCardData}
              />
            </div>
          </Formsy>
        ) : null}
        <Text className={`flex-row row-center ${!userData.name ? '' : 'hide'}`} size={14}>
          Por favor dinos tu nombre y apellidos. Estos serán verificados al momento de realizar
          alguna solicitud de retiro.
        </Text>
        <div className="my-3 mx-auto">
          <Button
            onClick={this.submitPayment}
            className='btn-pick btn btn-sm btn-default'
            disabled={this.props.errorOtraCantidad || (!userData.name && !this.state.canSubmit)}
            type="submit"
            bgColor='#88ba44'
            style={{
              maxWidth: 300,
              margin: '1em auto'
            }}
          >
            <span className="">
              <div>
                {' '}
                <i className="ion-ios-barcode-outline" />
                {` Obtener tu referencia `}
              </div>
            </span>
          </Button>
        </div>
        <StoresAvailable />
      </React.Fragment>
    );
  }
}

export default StorePayments;
