import utils from '../../utils/utils';

const localStorage = window.localStorage;

const debug = process.env.NODE_ENV === 'development';
const gaID = 'UA-63888104-1';
const gaAW = 'AW-734582992';

/**
 * Thi function pass the location to the instance of pageview of ReactGA
 * @param location
 */

const userInfo = utils.getUserInfo();
// console.log(userInfo);

const gaUtils = {
  setHasSentAnalytics: (status) => {
    // console.log( 'SetAnalytics to:' , status );
    localStorage.setItem('hasSentAnalytics', status);
  },
  hasSentAnalytics: () => {
    let hasSent = localStorage.getItem('hasSentAnalytics');
    if (!hasSent) {
      return false;
    }
    try {
      hasSent = JSON.parse(hasSent);
      return hasSent;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  handleDocumentAttributes: (data) => {
    // console.log( 'Document Data  Handler' );
    let docTitle = 'Pickwin';

    if (data && data.params && data.params.hasOwnProperty('slug')) {
      docTitle = data.params.slug.replace(/-/g, ' ');
    }

    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };

    document.title = docTitle;
    let params = {
      page_title: docTitle,
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_page_view: true,
    };

    // params = {...params, ...sendTo};
    if (window.location.pathname !== '/') {
      window.gtag('event', 'page_view', params);
    }
  },
  initGa: (history) => {
    let configGA = {
      send_page_view: false,
      linker: {
        domains: ['pickwin.mx'],
        accept_incoming: true
      },
      userId: '',
    };

    if (userInfo) {
      configGA.userId = userInfo.id;
    }

    window.gtag('config', gaID, configGA);
    window.gtag('config', gaAW);
    gaUtils.trackPageView(history.location);
  },
  trackPageView: (location) => {
    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };

    let params = {
      page_title: window.document.title,
      page_location: window.location.href,
      page_path: location.pathname,
      send_page_view: true,
    };

    // params = {...params, ...sendTo};
    window.gtag('event', 'page_view', params);
  },
  trackSuccessfullRegister: () => {
    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };
    // window.gtag('event', 'sign_up', sendTo);
    window.gtag('event', 'sign_up');
  },
  trackUserLogin: () => {
    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };
    // window.gtag('event', 'login', sendTo);
    window.gtag('event', 'login');
  },
  trackUserId: (userId) => {
    if (userId) {
      window.gtag('set', {'user_id': String(userId)});
    }
  },
  setEnhancedUserData: (user) => {
    console.log('setEnhancedUserData', user)
    var data = { "email": user.email };
    if ( user.phone && user.phone != '' ) {
      data['phone_number'] = `+${user.phone}`;
    }
    if ( user.name && user.name != '' ) {
      data['address'] = {};
      data['address']['first_name'] = user.name;
    }
    if ( user.last_name && user.last_name != '' ) {
      data['address']['last_name'] = user.last_name;
    }
    window.gtag('set', 'user_data', data);
  },
  trackUserGameRegistration: (data, gameType) => {
    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };
    let params = {
      event_category: 'Participacion',
      event_action: 'Registro Concurso',
      event_label: `${data.sport.name}`,
      value: data.entry_fee / 100,
    };
    // params = {...params, ...sendTo};
    window.gtag('event', 'registro_concurso', params);
  },
  trackUserPurchase: (data) => {

    let user = utils.getUserInfo();
    // let sendTo = {
    //   'send_to' : [
    //       gaID,
    //       gaAW + '/mcSKCJzXl-MCENCxo94C'
    //     ]
    // };

    let params = {
      label: 'OPENPAY',
      value: data.amount/100,
      transaction_id: data.transactionId,
      affiliation: 'OPENPAY',
      currency: 'MXN',
      email: user.email
    };
    // params = {...params, ...sendTo};

    // se necesita esto?
    // let dataLayer = window.dataLayer || [];
    // dataLayer.push('email', user.email);
    // dataLayer.push('value', data.amount);
    // dataLayer.push('transaction_id', data.transactionId);

    window.gtag('set', 'user_data', {
      'email': user.email,
    });
    window.gtag('event', 'purchase', params);

    // dataLayer.push({
    //   event: "purchase",
    //   email: userInfo.email,
    //   eventModel: {
    //     label: "OPENPAY",
    //     value: 5000,
    //     transaction_id: "trfan8emiq0fzxvmm0fp",
    //     affiliation: "OPENPAY",
    //     currency: "MXN"
    //   },
    //   gtm.uniqueEventId: 15
    // })


    // window.gtag('event', 'conversion', {
    //   'send_to': 'AW-734582992/CzPJCNv76q8DENCxo94C',
    //   'value': data.amount,
    //   'currency': 'MXN',
    //   'transaction_id': data.transactionId,
    // });

  },
};

export default gaUtils;
